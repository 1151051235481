import { Container } from '@components/ui'
import Button from '@components/ui/Button'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'

interface ErrorProps {
  code: number
}

const Error = ({ code }: ErrorProps) => {
  const { t } = useTranslation('common')
  const cardData = [
    {
      hex: '#948574',
      icon: {
        alt: 'FAQs',
        url: '/FAQs.svg',
      },
      link: '/information',
      title: 'FAQs',
    },
    {
      hex: '#C9AC87',
      icon: {
        alt: 'Troubleshooting',
        url: '/Troubleshooting.svg',
      },
      link: '/limewash-paint-trouble-shooting',
      title: 'Troubleshooting',
    },
    {
      hex: '#A2A4A3',
      icon: {
        alt: 'Orders',
        url: '/Orders.svg',
      },
      link: '/limewash-paint-order-delivery-shipping',
      title: 'Orders + Delivery',
    },
    {
      hex: '#A68054',
      icon: {
        alt: 'Trade',
        url: '/Trade.svg',
      },
      link: '/limewash-paint-trade-collaboration',
      title: 'Trade + Collaborations',
    },
  ]
  return (
    <Container>
      <div className="mx-4 mt-16 mb-20 items-center justify-center text-center lg:mx-16 lg:mt-20 lg:mb-30">
        <h1 className="heading-mobile-1 lg:heading-1">
          {code === 404 ? `Page not found` : code}
        </h1>
        <div className="body-1 mt-4">
          {t('sorry-page-looking-for-not-found')}
        </div>
        <div className="mt-8 hidden lg:block">
          <Link href="/" legacyBehavior>
            <Button className="!px-6">{t('go-to-homepage')} </Button>
          </Link>
        </div>
        <div className="mt-8 lg:hidden">
          <Link href="/" legacyBehavior>
            <Button width="100%">{t('go-to-homepage')}</Button>
          </Link>
        </div>
        <p className="body-1 mt-12">Or, maybe one of these pages could help</p>
        <div className="mt-8 lg:mt-4 lg:grid lg:grid-cols-6 lg:gap-x-8">
          <div className="col-span-4 grid grid-cols-2 gap-6 lg:col-start-2 lg:grid-cols-4 lg:gap-8">
            {cardData?.map((item: any, index: number) => (
              <div
                className="col-span-1 aspect-square w-full overflow-hidden"
                style={{
                  backgroundColor: `${item?.hex}`,
                }}
                key={index}
              >
                <Link
                  href={item?.link}
                  className="relative flex h-full w-full flex-col items-center justify-center text-center"
                >
                  <div className="absolute top-1/4 aspect-square w-12 lg:w-14">
                    <Image
                      src={item?.icon?.url}
                      alt={item?.icon?.alt}
                      fill
                      className="flex items-center justify-center"
                    />
                  </div>
                  <div className="mt-auto mb-4 flex justify-center lg:mb-4">
                    <div className="body1 px-2 text-white lg:px-3">
                      {item?.title}
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="body-1 mt-12">
          {`If not, please feel free to `}
          <Link href="/collection/colour-lab" className="underline">
            contact us
          </Link>
          {` or use our friendly Feedback Widget`}
        </div>
      </div>
    </Container>
  )
}

export default Error
