import { Error, Layout } from '@components/common'
import getGlobalStaticProps from '@lib/globalStaticProps'
import type { GetStaticPropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { createClient } from 'prismicio'

export async function getStaticProps({
  preview,
  locale,
  locales,
  previewData,
}: GetStaticPropsContext) {
  const client = createClient({ previewData })
  const globalProps = await getGlobalStaticProps(client, locale)
  const translations = await serverSideTranslations(locale ?? 'en-au', [
    'common',
  ])
  return {
    props: {
      ...globalProps,
      ...translations,
    },
    //revalidate: 86400,
  }
}

export default function NotFound() {
  return <Error code={404} key="notfound-404" />
}

NotFound.Layout = Layout
